import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import "./s.css";
import { apiUrl } from "../../config";

class ImageMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      mode: "check", // 'check' para certa, 'circle' para círculo, 'square' para quadrado
      selectedMarkerIndex: null, // Índice do marcador selecionado
      resizing: false, // Se está redimensionando o quadrado
      item: [],
      size: 15, // Tamanho padrão dos desenhos
    };
    this.canvasRef = React.createRef();
  }

  handleImageClick = (event) => {
    const { mode, size } = this.state;
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    this.setState(
      (prevState) => ({
        markers: [
          ...prevState.markers,
          {
            x: x,
            y: y,
            type: mode,
            width: size,
            height: size,
            offsetX: size / 2, // Centraliza o quadrado
            offsetY: size / 2, // Centraliza o quadrado
          },
        ],
      }),
      this.updateCanvas
    );
  };

  setMode = (mode) => {
    this.setState({ mode });
  };

  handleMarkerClick = (index) => {
    this.setState({ selectedMarkerIndex: index });
  };

  handleMouseMove = (event) => {
    const { selectedMarkerIndex, markers, resizing } = this.state;
    if (selectedMarkerIndex !== null) {
      const rect = this.canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const updatedMarkers = [...markers];
      if (resizing) {
        const width = x - updatedMarkers[selectedMarkerIndex].x;
        const height = y - updatedMarkers[selectedMarkerIndex].y;
        updatedMarkers[selectedMarkerIndex] = {
          ...updatedMarkers[selectedMarkerIndex],
          width,
          height,
        };
      } else {
        updatedMarkers[selectedMarkerIndex] = {
          ...updatedMarkers[selectedMarkerIndex],
          x,
          y,
        };
      }

      this.setState({ markers: updatedMarkers }, this.updateCanvas);
    }
  };

  handleMouseUp = () => {
    this.setState({ selectedMarkerIndex: null, resizing: false });
  };

  handleUndo = () => {
    this.setState(
      (prevState) => ({
        markers: prevState.markers.slice(0, -1),
      }),
      this.updateCanvas
    );
  };

  handleSizeChange = (event) => {
    this.setState({ size: parseInt(event.target.value, 10) });
  };

  updateCanvas = () => {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      canvas.width = 600;
      canvas.height = 500;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      this.state.markers.forEach((marker) => {
        if (marker.type === "check") {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 4;
          ctx.beginPath();
          ctx.moveTo(marker.x - 10, marker.y);
          ctx.lineTo(marker.x, marker.y + 10);
          ctx.lineTo(marker.x + 15, marker.y - 10);
          ctx.stroke();
        } else if (marker.type === "circle") {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.arc(marker.x, marker.y, marker.width / 2, 0, 2 * Math.PI);
          ctx.stroke();
        } else if (marker.type === "square") {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.beginPath();
          // Desenha o quadrado centralizado
          ctx.rect(
            marker.x - marker.offsetX,
            marker.y - marker.offsetY,
            marker.width,
            marker.height
          );
          ctx.stroke();
        }
      });
    };

    image.src = this.props.imageSrc;
  };

  handleUpload = () => {
    const canvas = this.canvasRef.current;
    canvas.toBlob((blob) => {
      this.props.onUpload(blob);
      /*
      const formData = new FormData();
     
      formData.append('imagem', blob, 'update.png');
      fetch(`${apiUrl}/solicitante/alterar/groupi/${this.state.item.id}`, {
        method: 'POST',
        body: formData,
      }).then(response => response.json())
        .then(data => {
          if (this.props.onFinish) {
            this.props.onFinish(data);
          }
        })
        .catch(error => {
          console.error('Erro:', error);
        });
*/
    }, "image/png");
  };

  componentDidMount() {
    this.setState({ item: this.props.item });

    this.updateCanvas();
  }
  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen == false) {
        this.setState({ markers: [] });
      }
    }
    this.updateCanvas();
  }

  render() {
    const { mode } = this.state;
    if (this.props.imageSrc == undefined) {
      return <>Carregando...</>;
    }
    return (
      <div
        className="cva"
        style={{ position: "relative", display: "inline-block" }}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
      >
        <canvas
          ref={this.canvasRef}
          onClick={this.handleImageClick}
          style={{ cursor: "pointer" }}
        ></canvas>

        <div className="maker-btn">
          <button
            onClick={() => this.setMode("check")}
            className={mode === "check" ? "active" : ""}
          >
            Checker
          </button>
          <button
            onClick={() => this.setMode("circle")}
            className={mode === "circle" ? "active" : ""}
          >
            Círculo
          </button>
          <button
            onClick={() => this.setMode("square")}
            className={mode === "square" ? "active" : ""}
          >
            Quadrado
          </button>
          <button
            onClick={this.handleUndo}
            disabled={this.state.markers.length === 0}
          >
            <FontAwesomeIcon icon={faUndo} color="#fff" /> Desfazer
          </button>
          <button
            onClick={(e) => {
              this.handleUpload();
            }}
          >
            <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
          </button>
        </div>

        <div className="size-slider">
          <label>Tamanho dos desenhos: {this.state.size}</label>
          <input
            type="range"
            min="5"
            max="300"
            value={this.state.size}
            onChange={this.handleSizeChange}
          />
        </div>
      </div>
    );
  }
}

export default ImageMarker;
